
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
@Component
export default class AddTypes extends Vue {
  @Prop({ default: false }) private showDialog!: boolean
  @Prop({ default: '' }) private orderId!: string
  @Prop({ default: '1' }) private type!: string
  private formInfo: {
    orderId: string;
    remarks: string;
    result: string;
  } = {
    orderId: '',
    remarks: '',
    result: '0'
  }

  @Watch('orderId', { immediate: true })
  getId (val: string) {
    if (val) {
      this.formInfo.orderId = val
    }
  }

  private submitShow = false

  onSubmit () {
    (this.$refs.formInfo as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.type === '1' ? this.$apis.assetScheduling.approvalAssetAllocate : this.$apis.assetScheduling.approvalAssetHandle, this.formInfo).then(() => {
          this.$message.success('保存成功')
          this.closeDialog()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  closeDialog () {
    this.$nextTick(() => {
      (this.$refs.formInfo as ElForm).resetFields()
    })
    this.$emit('update:showDialog', false)
    this.$emit('success')
  }
}
