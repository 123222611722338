

import { KeepAlive } from '@/utils/decorators'
import { Vue, Component } from 'vue-property-decorator'
import Examine from '@/views/assetScheduling/transfer/Examine.vue'

@Component({
  name: 'DisposalList',
  components: { Examine }
})
@KeepAlive
export default class DisposalList extends Vue {
  searchForm = {
    orderNumber: '', // 单据编号
    startDate: '', // 开始时间
    endDate: '' // 结束时间
  }

  dialogVisible = false

  dataArr = []

  page = 1
  size = 10
  total = 0
  items = []
  private showDialog = false
  private id = ''
  private type = ['待审核', '通过', '驳回']

  get useId () {
    const userInfo = window.sessionStorage.getItem('userInfo') || ''
    return userInfo ? JSON.parse(userInfo).userId : ''
  }

  created () {
    this.loadData()
  }

  loadData () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetHandleByPage, {
      ...this.searchForm,
      page: this.page,
      size: this.size
    }).then(res => {
      this.items = res.list
      this.total = res.total
    })
  }

  dateChange (date: any) {
    this.searchForm.startDate = (date && date[0]) || ''
    this.searchForm.endDate = (date && date[1]) || ''
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  isShowExamine (row: any) {
    return (row.disposeProcessState === '1' && this.useId === row.disposeId)
  }

  // 审批
  onExamine (orderId: string) {
    this.showDialog = true
    this.id = orderId
  }

  success () {
    this.id = ''
    this.loadData()
  }
}
